//Projects Data
export const FETCHING_PROJECT_DATA = 'FETCHING_PROJECT_DATA';
export const FETCHED_PROJECTS_DATA = 'FETCHED_PROJECTS_DATA';
export const FETCHING_STAFF_DATA = 'FETCHING_STAFF_DATA';
export const FETCHED_STAFF_DATA = 'FETCHED_STAFF_DATA';
export const FETCHING_AWARDS_DATA = 'FETCHING_AWARDS_DATA';
export const FETCHED_AWARDS_DATA = 'FETCHED_AWARDS_DATA';
export const FETCHING_SLIDESHOW_DATA = 'FETCHING_SLIDESHOW_DATA';
export const FETCHED_SLIDESHOW_DATA = 'FETCHED_SLIDESHOW_DATA';
export const FETCHING_ABOUT_DATA = 'FETCHING_ABOUT_DATA';
export const FETCHED_ABOUT_DATA = 'FETCHED_ABOUT_DATA';
export const FETCHED_CONTACT_DATA = 'FETCHED_CONTACT_DATA';
export const FETCHING_CONTACT_DATA = 'FETCHING_CONTACT_DATA';
export const ERROR = 'ERROR';
export const FETCHED_PROJECT_DATA = "FETCHED_PROJECT_DATA"
export const SEARCH_VALUE_DATA = "SEARCH_VALUE_DATA"
export const ADD_CATEGORY_FILTER = "ADD_CATEGORY_FILTER"
export const REMOVE_CATEGORY_FILTER = "REMOVE_CATEGORY_FILTER"

//Language
export const LOCALE_SET = 'LOCALE_SET';

